@tailwind base;

html {
  /* -webkit-touch-callout: none; */
  /* -webkit-tap-highlight-color: transparent; */

  font-family: 'メイリオ', Meiryo, '游ゴシック体', YuGothic, '游ゴシック',
    'Yu Gothic', sans-serif;
}

.debug {
  background: red !important;
}

/* That little pointy arrow in message bubble */
.chat-mine:after {
  content: '';
  position: absolute;
  z-index: 1;
  bottom: -5px;
  right: 0;
  width: 15px;
  height: 20px;
  background: inherit;
  border-bottom-left-radius: 20px;
}

.animate-shake {
  animation: shake 1s cubic-bezier(0.36, 0.07, 0.19, 0.97) both;
  transform: translate3d(0, 0, 0);
  backface-visibility: hidden;
  perspective: 1000px;
}

@keyframes shake {
  10%,
  90% {
    transform: translate3d(-1px, 0, 0);
  }

  20%,
  80% {
    transform: translate3d(2px, 0, 0);
  }

  30%,
  50%,
  70% {
    transform: translate3d(-4px, 0, 0);
  }

  40%,
  60% {
    transform: translate3d(4px, 0, 0);
  }
}

@keyframes wobble {
  0% {
    transform: rotate(0deg);
  }
  25% {
    transform: rotate(10deg);
  }
  75% {
    transform: rotate(-10deg);
  }
  100% {
    transform: rotate(0deg);
  }
}
@-webkit-keyframes wobble {
  0% {
    -webkit-transform: rotate(0deg);
  }
  25% {
    -webkit-transform: rotate(10deg);
  }
  75% {
    -webkit-transform: rotate(-10deg);
  }
  100% {
    -webkit-transform: rotate(0deg);
  }
}

@keyframes jiggle {
  0% {
    transform: rotate(-5deg);
  }
  50% {
    transform: rotate(5deg);
  }
}

@-webkit-keyframes jiggle {
  0% {
    -webkit-transform: rotate(-5deg);
  }
  50% {
    -webkit-transform: rotate(5deg);
  }
}

@-moz-keyframes jiggle {
  0% {
    -moz-transform: rotate(-5deg);
  }
  50% {
    -moz-transform: rotate(5deg);
  }
}

.animate-jiggle {
  -webkit-animation: jiggle 0.2s infinite;
  animation: jiggle 0.2s infinite;
}
.animate-wobble {
  -webkit-animation: wobble 2s linear infinite;
}
.animate-wobble-slow {
  -webkit-animation: wobble 5s linear infinite;
}

.Toastify__toast-container {
  margin-top: 20px;
}

@tailwind components;
@tailwind utilities;
